import React from "react";
import { UrlCollection } from "../../common/url-collection";
import {
  APIUrlDefault,
  DomainAdminSide,
  getUserInfo,
  removeCookies,
  setCookies,
  TokenKey,
} from "../../utils/configuration";
import "./home.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faSignOutAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { modules } from "../../common/profileModules";
import * as accAction from "../../redux/store/account/account.store";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: getUserInfo() ? true : false,
      user: getUserInfo(),
      screenAllow: [],
    };
  }

  componentDidMount() {
    if(this.state.isLogin) {
      this.GetScreenAllow();
    }
  }

  GetScreenAllow() {
    accAction.GetScreenAllow().then(res => {
      this.setState({
        screenAllow: modules.filter(item => {
          if (res.content.some(x => x.code === item.code))
            return item
        })
      })
    }).catch(error => console.log(error))
  }

  UNSAFE_componentWillMount() {
    const { isLogin, user } = this.state;
    if (isLogin && user && user.userRole) {
      if (user.email.toLowerCase() == "xinykien_sonla@gmail.com")
        window.location.replace(DomainAdminSide + "/dang-nhap");
      else return;
    } else {
      removeCookies("isShowDialog");
      removeCookies("isLockScreen");
      removeCookies(TokenKey.token);
      removeCookies(TokenKey.refreshToken);
      removeCookies(TokenKey.returnUrl);
      setCookies(TokenKey.returnUrl, window.location.href);
      window.location.replace(DomainAdminSide + "/dang-nhap");
    }
  }
  render() {
    const { user } = this.state;
    return (
      <div className="container bg-white wrapper">
        <div className="d-flex align-items-center">
          <img
            src={process.env.PUBLIC_URL + "/logo.png"}
            alt="Logo"
            width="70"
          />
          <div className="title font-weight-bold">
            PHẦN MỀM CƠ SỞ DỮ LIỆU QUẢN LÝ QUY HOẠCH, CUNG CẤP THÔNG TIN QUY
            HOẠCH TRỰC TUYẾN TRÊN NỀN TẢNG HỆ THỐNG CÔNG NGHỆ THÔNG TIN ĐỊA LÝ
            TOÀN CẦU, ĐIỆN TOÁN ĐÁM MÂY TRÊN ĐỊA BÀN TỈNH LÀO CAI
          </div>
          <div>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item dropdown no-arrow">
                <a
                  className="nav-link dropdown-toggle d-flex"
                  href="#"
                  id="userDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    className="img-profile rounded-circle"
                    style={{ width: 50, height: 50 }}
                    src={
                      user && user.avatar && user.avatar !== "null"
                        ? APIUrlDefault + user.avatar
                        : process.env.PUBLIC_URL + "/user-default.png"
                    }
                  />
                  <div class="admin__text d-flex align-items-center">
                    <span className="mr-2 text-secondary font-weight-bold d-lg-inline name">
                      {user && user.fullName}
                    </span>
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="text-secondary"
                    />
                  </div>
                </a>

                <div
                  className="dropdown-menu dropdown-menu-right shadow mr-1"
                  aria-labelledby="userDropdown"
                >
                  <Link className="dropdown-item" to={UrlCollection.MyAccount}>
                    <FontAwesomeIcon
                      icon={faUser}
                      className="fa-sm fa-fw mr-2 text-gray-400"
                    />
                    Thông tin tài khoản
                  </Link>
                  {this.state.screenAllow.map((x) => (
                    <a className="dropdown-item" href={x.url} target={x.url === UrlCollection.PAHT ? "_blank" : ""}>
                      <img
                        src={x.logo}
                        alt="Folder"
                        style={{ width: 15, height: 15 }}
                      />
                      <span className="ml-2">{x.title}</span>
                    </a>
                  ))}
                  <a
                    className="dropdown-item"
                    href={DomainAdminSide + "/dang-nhap"}
                    onClick={this.onLogout}
                  >
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      className="fa-sm fa-fw mr-2 text-gray-400"
                    />
                    Đăng xuất
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="row module-box mt-5">
          {this.state.screenAllow.map((x) => (
            <a
              href={x.url}
              className="text-decoration-none col card m-3 align-items-center"
              target={x.url === UrlCollection.PAHT ? "_blank" : ""}
            >
              <img
                src={x.logo}
                alt="Folder"
                style={{ width: 60, height: 60, marginTop: 20 }}
              />
              <div className="card-body  m-2 ml-2">{x.title}</div>
            </a>
          ))}
        </div>
      </div>
    );
  }
}

export default HomePage;
