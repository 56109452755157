const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
        api: "https://localhost:5002/",
        paht_api: "https://pahtsonla-api.cgis.asia/",
        media_url: "https://laocai-api.cgis.asia/",
        domainAdminSide: "http://localhost:3000",
        domainUserSide: "http://localhost:3000",
        domainName: "",
        workSpace: "laocai",
        wmsBaseLink: "https://geo.cgis.asia/geoserver/laocai/wms/",
  },
    production: {
      api: "https://laocai-api.cgis.asia/",
      paht_api: "https://pahtsonla-api.cgis.asia/",
      media_url: "https://laocai-api.cgis.asia/",
      domainAdminSide: "https://admin.laocai.cgis.asia",
      domainUserSide: "https://laocai.cgis.asia",
      domainName: "cgis.asia",
      workSpace: "laocai",
      wmsBaseLink: "https://geo.cgis.asia/geoserver/laocai/wms/",
  }
  // production: {
  //   api: "https://api.tracuuquyhoachsonla.vn/",
  //   paht_api: "https://paht-api.tracuuquyhoachsonla.vn/",
  //   media_url: "https://api.tracuuquyhoachsonla.vn/",
  //   domainAdminSide: "https://admin.tracuuquyhoachsonla.vn",
  //   domainUserSide: "https://tracuuquyhoachsonla.vn",
  //   domainName: "tracuuquyhoachsonla.vn",
  //   workSpace: "sonla",
  //   wmsBaseLink: "https://geo.tracuuquyhoachsonla.vn/geoserver/sonla/wms/",
  // },
};

module.exports = apiEnvironment[env];
