import React from "react";
import "date-fns";
import { useForm } from "react-hook-form";

const SearchNews = (props) => {
  const {
    searchData: { title, status },
    onChangeTitle,
    onChangeStatus,
    refresh,
    onSubmit,
  } = props;

  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  return (
    <div class="wrap__content-page qlhs-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div class="form-row">
          <div class="form-group col-12 col-lg-4">
            <input
              id="input-search"
              type="text"
              value={title}
              name="title"
              onChange={onChangeTitle}
              class="form-control"
              placeholder="Tiêu đề"
              inputRef={register}
            />
          </div>
          <div class="form-group col-12 col-lg-3">
            <select
              name="status"
              value={status}
              class="custom-select"
              onChange={onChangeStatus}
              inputRef={register}
              placeholder="Trạng thái"
            >
              <option value={2}>----------------</option>
              <option value={1}>Kích hoạt</option>
              <option value={0}>Không kích hoạt</option>
            </select>
          </div>
          <div class="form-group col-12 col-lg-3" style={{ display : "flex"}}>
            <button class="btn btn-ct btn-default-ct" onClick={refresh}>
              Xóa
            </button>
            <button class="btn btn-ct btn-primary-ct" type="submit">
              Tìm kiếm
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchNews;
