/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, useLocation } from "react-router-dom";
import { UrlCollection } from "../../common/url-collection";
import LinkAdministratorItems from "./child-components/link-adminstrator-items.view";
import LinkSubMenu from "./child-components/link-sub-items.view";
import { useHistory } from "react-router-dom";

//--- Material Icon
import HomeIcon from "@material-ui/icons/Home";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SettingsIcon from "@material-ui/icons/Settings";
//--- Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import * as clientSettingAction from "../../redux/store/client_setting/client_setting.store";
import * as appActions from "../../core/app.store";

//--- Style
import "./sidebar.scss";

function Sidebar(props) {
  const {
    settings,
    getSettings,
    isCollapsed,
    expandSidebar,
    collapseSidebar,
    isDirty,
  } = props;
  const history = useHistory();
  const isMobile = window.innerWidth < 1281;

  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const [openQLQH, setOpenQLQH] = useState(false);
  const [openQHHTKT, setOpenQHHTKT] = useState(false);
  const [openQHCC, setOpenQHCC] = useState(false);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  useEffect(() => {
    setClientSetting(settings);
  }, [settings]);

  useEffect(() => {
    if (isMobile) {
      collapseSidebar();
    }
  }, [collapseSidebar, isMobile]);

  const [clientSetting, setClientSetting] = useState();
  const currentLocation = useLocation();

  const onMouseEnter = () => {
    (isDirty || isMobile) && isCollapsed && expandSidebar();
  };

  const onMouseLeave = () => {
    (isDirty || isMobile) && collapseSidebar();
  };

  const currentIsHomePage =
    currentLocation.pathname === "/" ||
    currentLocation.pathname === UrlCollection.Dashboard;
  const currentIsPlanningAnnouncementProcessPage =
    currentLocation.pathname.includes(
      UrlCollection.PlanningAnnouncementProcess
    );
  const currentIsPlanningCCPage =
    currentLocation.pathname.includes(
      UrlCollection.PlanningCC
    );
  const currentIsSliderPage = currentLocation.pathname.includes(
    UrlCollection.Slider
  );
  const currentIsMapDataPage = currentLocation.pathname.includes(
    UrlCollection.MapData
  );
  const currentIsNewsPage = currentLocation.pathname.includes(
    UrlCollection.News
  );
  const currentIsDocumentManagementPage = currentLocation.pathname.includes(
    UrlCollection.DocumentManagement
  );
  const currentIsPTQD = currentLocation.pathname.includes(UrlCollection.PTQD);
  const currentIsQTHT = currentLocation.pathname.includes(UrlCollection.QTHT);
  const currentIsQHHTKT = currentLocation.pathname.includes(
    UrlCollection.QH_HTKT
  );
  const currentIsOtherPlanningPage =
    currentLocation.pathname.includes(
      UrlCollection.OtherPlanning
    );
  const currentIsAdministratorPages =
    !currentIsHomePage &&
    !currentIsPlanningAnnouncementProcessPage &&
    !currentIsPlanningCCPage &&
    // !currentIsSliderPage &&
    !currentIsMapDataPage &&
    !currentIsNewsPage &&
    !currentIsDocumentManagementPage &&
    !currentIsPTQD &&
    !currentIsQTHT &&
    !currentIsQHHTKT &&
    !currentIsOtherPlanningPage;

  const isShowQHDT = currentLocation.pathname.includes("/qhdt");
  const isShowHeThong = currentLocation.pathname.includes("/he-thong");
  const isShowPTQD = currentLocation.pathname.includes("/ptqd");

  useEffect(() => {
    setSubMenuOpen(currentIsAdministratorPages);
  }, [currentIsAdministratorPages]);

  useEffect(() => {
    setOpenQLQH(currentIsPlanningAnnouncementProcessPage);
  }, [currentIsPlanningAnnouncementProcessPage]);

  useEffect(() => {
    setOpenQHCC(currentIsPlanningCCPage);
  }, [currentIsPlanningCCPage]);

  useEffect(() => {
    setOpenQHHTKT(currentIsQHHTKT);
  }, [currentIsQHHTKT]);

  return clientSetting ? (
    <>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={"aside" + (isCollapsed ? " toggled" : "")}
      >
        <ul
          className={
            "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion aside__menu" +
            (isCollapsed ? " toggled" : "")
          }
          id="accordionSidebar"
        >
          <hr className="sidebar-divider my-0" />

          {isShowQHDT && (
            <>
              <li className="nav-item">
                <Link
                  className={`nav-link ${currentIsHomePage && "is-active"}`}
                  to="/qhdt/dashboard"
                  onClick={() => setSubMenuOpen(false)}
                >
                  <HomeIcon fontSize="small" className="mr-2" />
                  {!isCollapsed && <span>Dashboard</span>}
                </Link>
              </li>
              <hr className="sidebar-divider mb-0" />
              <li
                className={`nav-item ${openQLQH && !isCollapsed ? "is-open" : ""
                  }`}
              >
                <a
                  className={`nav-link ${currentIsPlanningAnnouncementProcessPage && "is-active"
                    }`}
                  to={UrlCollection.PlanningAnnouncementProcess}
                  onClick={() => {
                    history.push(UrlCollection.PlanningAnnouncementProcess);
                    setOpenQLQH(!openQLQH);
                  }}
                >
                  <AssignmentIcon fontSize="small" className="mr-2" />
                  {!isCollapsed && <span>Quản lý quy hoạch</span>}
                  {!isCollapsed && (
                    <>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="float-right mt-1 chevron"
                      />
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="float-right mt-1 chevron"
                      />
                    </>
                  )}
                </a>
                <ul className="aside__menu-sub">
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.LinkMapProcess}
                      title="Bản đồ"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.ConsultTheCommunity}
                      title="Xin ý kiến"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.PlanningRelate}
                      title="Quy hoạch liên quan"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.DocumentSetting}
                      title="Thiết lập hồ sơ"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.QHTNews}
                      title="Tin tức"
                    />
                  </li>
                </ul>
              </li>
              <li
                className={`nav-item ${openQHCC && !isCollapsed ? "is-open" : ""
                  }`}
              >
                <a
                  className={`nav-link ${currentIsPlanningCCPage && "is-active"
                    }`}
                  to={UrlCollection.PlanningCC}
                  onClick={() => {
                    history.push(UrlCollection.PlanningCC);
                    setOpenQHCC(!openQHCC);
                  }}
                >
                  <AssignmentIcon fontSize="small" className="mr-2" />
                  {!isCollapsed && <span>Quy hoạch các cấp</span>}
                  {!isCollapsed && (
                    <>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="float-right mt-1 chevron"
                      />
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="float-right mt-1 chevron"
                      />
                    </>
                  )}
                </a>
                <ul className="aside__menu-sub">
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.LinkMapPlanningCC}
                      title="Bản đồ"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.ConsultPlanningCC}
                      title="Xin ý kiến"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.PlanningCCRelate}
                      title="Quy hoạch liên quan"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.DocumentPlanningCC}
                      title="Thiết lập hồ sơ"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.QHCCNews}
                      title="Tin tức"
                    />
                  </li>
                </ul>
              </li>
              <li
                className={`nav-item ${openQHHTKT && !isCollapsed ? "is-open" : ""
                  }`}
              >
                <a
                  className={`nav-link ${currentIsQHHTKT && "is-active"}`}
                  to={UrlCollection.QH_HTKT}
                  onClick={() => {
                    history.push(UrlCollection.QH_HTKT);
                    setOpenQHHTKT(!openQHHTKT);
                  }}
                >
                  <AssignmentIcon fontSize="small" className="mr-2" />
                  {!isCollapsed && <span>Nội dung QH hạ tầng kỹ thuật</span>}
                  {!isCollapsed && (
                    <>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="float-right mt-1 chevron"
                      />
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="float-right mt-1 chevron"
                      />
                    </>
                  )}
                </a>
                <ul className="aside__menu-sub">
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.QH_HTKT_LINK_MAP}
                      title="Bản đồ"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.QH_HTKT_CONSULT}
                      title="Xin ý kiến"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.QH_HTKT_Relate}
                      title="Quy hoạch liên quan"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.QH_HTKT_SETUP_DOCUMENT}
                      title="Thiết lập hồ sơ"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.QHHTKTNews}
                      title="Tin tức"
                    />
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${currentIsOtherPlanningPage && "is-active"
                    }`}
                  to={UrlCollection.OtherPlanning}
                  onClick={() => {
                    history.push(UrlCollection.OtherPlanning);
                  }}
                >
                  <AssignmentIcon fontSize="small" className="mr-2" />
                  {!isCollapsed && <span>Quy hoạch khác</span>}
                </a>
              </li>
            </>
          )}
          {/* <li className="nav-item">
            <Link
              target="_blank"
              className={`nav-link`}
              //to={UrlCollection.PAHT}
              to={{ pathname: UrlCollection.PAHT }}
            >
              <PostAddIcon fontSize="small" className="mr-2" />
              {!isCollapsed && <span>Phản ánh hiện trường</span>}
            </Link>
          </li> */}
          {isShowPTQD && (
            <li className="nav-item">
              <Link
                className={`nav-link ${currentIsPTQD && "is-active"}`}
                to="/ptqd"
                onClick={() => setSubMenuOpen(false)}
              >
                <AssignmentIcon fontSize="small" className="mr-2" />
                {!isCollapsed && <span>Phân tích - quyết định</span>}
              </Link>
            </li>
          )}
          {/* {isShowQHDT && (
            <li className="nav-item">
              <Link
                className={`nav-link ${currentIsNewsPage && "is-active"}`}
                to={UrlCollection.News}
                onClick={() => setSubMenuOpen(false)}
              >
                <PostAddIcon fontSize="small" className="mr-2" />
                {!isCollapsed && <span>Tin tức</span>}
              </Link>
            </li>
          )} */}
          {isShowHeThong && (
            <>
              <li
                className={`nav-item ${isSubMenuOpen && !isCollapsed ? "is-open" : ""
                  }`}
              >
                <a
                  className={`nav-link ${currentIsAdministratorPages && "is-active"
                    }`}
                  onClick={() => {
                    history.push(UrlCollection.EmailTemplate);
                    setSubMenuOpen(!isSubMenuOpen);
                  }}
                >
                  <SettingsIcon fontSize="small" className="mr-2" />
                  {!isCollapsed && <span>Administrator</span>}
                  {!isCollapsed && (
                    <>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="float-right mt-1 chevron"
                      />
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="float-right mt-1 chevron"
                      />
                    </>
                  )}
                </a>
                <Link></Link>
                <ul className="aside__menu-sub">
                  <li>
                    <LinkAdministratorItems
                      // icon={EmailIcon}
                      pageLink={UrlCollection.EmailTemplate}
                      title="Email Template"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      // icon={AllInboxIcon}
                      pageLink={UrlCollection.EmailGenerated}
                      title="Khởi tạo email"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      // icon={ContactPhoneIcon}
                      pageLink={UrlCollection.ContactManagement}
                      title="Liên hệ"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //  icon={PeopleIcon}
                      pageLink={UrlCollection.RoleManagement}
                      title="Chức vụ"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //  icon={AccountBoxIcon}
                      pageLink={UrlCollection.Log}
                      title="Quản lý log"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //  icon={AccountBoxIcon}
                      pageLink={UrlCollection.UserManagement}
                      title="Quản lý người dùng"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //   icon={() => <img className="mr-2 mt-n1" src={require("../../assets/icon/commune.png")} alt="commune" />}
                      pageLink={UrlCollection.CommuneManagement}
                      title="Quản lý xã/phường"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //   icon={() => <img className="mr-2 mt-n1" src={require("../../assets/icon/district.png")} alt="district" />}
                      pageLink={UrlCollection.DistrictManagement}
                      title="Quản lý quận/huyện"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //   icon={() => <img className="mr-2 mt-n1" src={require("../../assets/icon/province.png")} alt="province" />}
                      pageLink={UrlCollection.ProvinceManagement}
                      title="Quản lý tỉnh/thành phố"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //   icon={() => <img className="mr-2 mt-n1" src={require("../../assets/icon/group-links.png")} alt="group-links" />}
                      pageLink={UrlCollection.LinkGroup}
                      title="Danh sách nhóm"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //   icon={() => <img className="mr-2 mt-n1" src={require("../../assets/icon/link.png")} alt="link" />}
                      pageLink={UrlCollection.ServiceLink}
                      title="Danh sách liên kết"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //  icon={HistoryIcon}
                      pageLink={UrlCollection.UserLogHistory}
                      title="Nhật ký người dùng"
                    />
                  </li>
                  {/* <li>
                <LinkAdministratorItems
                  // icon={ViewComfyIcon}
                  pageLink={UrlCollection.LandType}
                  title="Quản lý loại đất"
                />
              </li> */}
                  {/* <li>
                <LinkAdministratorItems
                  // icon={ViewAgendaIcon}
                  pageLink={UrlCollection.LandTypeDetail}
                  title="Quản lý chi tiết loại đất"
                />
              </li> */}
                  <li>
                    <LinkAdministratorItems
                      //  icon={CallToActionIcon}
                      pageLink={UrlCollection.OpinionForm}
                      title="Tạo form ý kiến"
                    />
                  </li>
                  {/* <li>
                <LinkAdministratorItems
                  //  icon={TableChartIcon}
                  pageLink={UrlCollection.TableStructure}
                  title="Sửa cấu trúc bảng"
                />
              </li> */}
                  <li>
                    <LinkAdministratorItems
                      pageLink={UrlCollection.PlanningUnit}
                      title="Đơn vị lập quy hoạch"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      pageLink={UrlCollection.Investor}
                      title="Chủ đầu tư"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      pageLink={UrlCollection.ApprovalAgency}
                      title="Cơ quan phê duyệt"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      pageLink={UrlCollection.Slider}
                      title="Slider"
                    />
                  </li>
                  <li className="nav-item"></li>
                </ul>
              </li>
            </>
          )}

          <hr className="sidebar-divider d-none d-md-block" />
        </ul>

        <div className="d-flex flex-column sidebar sidebar-dark w-100">
          <div class="copyright">
            <p>{clientSetting?.copyright}</p>
          </div>
        </div>
      </div>
    </>
  ) : null;
}

const mapStateToProps = (state) => ({
  settings: state.clientSetting.clientSetting,
  isCollapsed: state.app.isCollapsed,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSettings: clientSettingAction.getSettings,
      expandSidebar: appActions.ExpandSidebar,
      collapseSidebar: appActions.CollapseSidebar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
